.root{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.sliderWrapper {
  position: relative;
  max-width: 720px;
  width: 100%;
  overflow: hidden;
}
.sliderBase {
  margin-bottom: -6px;
  width: 100%;
  height: auto;
}
.sliderItems {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 3;
}
.sliderItem {
  position:absolute;
  bottom: -25px;
  z-index: 4;
  // right: 0,;
  left: 18px;
  width: auto;
  height: auto;
  opacity: 1;
  transition: opacity 0.6s ease-in;
}
.sliderItemHidden {
  opacity: 0;
}



