.root {
  width: 100%;
  max-width: 1920px;
  margin: 40px auto 0 auto;
  &__noBanner {
    margin-top: 200px;
    @media (max-width: 768px) {
      margin-top: 105px;
    }
    @media (max-width: 375px) {
      margin-top: 113px;
    }
  }
}

.success {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 740px;
  height: 478px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  border: none;
  outline: none;
  justify-content: space-between;
  align-items: center;
  padding-top: 31px;
  padding-bottom: 38px;
  @media (max-width: 880px) {
    width: 540px;
    height: 478px;
  }
  @media (max-width: 580px) {
    width: 100%;
    height: 478px;
  }
}

.exit {
  align-self: flex-end;
  padding-right: 34px;
  color: #757575;
  font-size: 17px;
  outline: none;
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.text {
  font-family: Montserrat, serif;
  color: #e64147;
  font-size: 30px;
  font-weight: 600;

  @media (max-width: 580px) {
    text-align: center;
    font-size: 26px;
  }
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  color: #404041;
  font-size: 20px;
  font-weight: 600;
}
.di {
  color: #e52428;
}

.btn {
  font-family: Montserrat, serif;
  width: 269px;
  height: 53px;
  background: #e64147;
  //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12),
  //  0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  color: #ffffff;
  outline: none;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 18.35px 33px;
}
