.container {
  max-width: 1456px;
  margin: 0 auto 72px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 0 21px;
  }

  @media (max-width: 600px) {
    padding: 0 15px;
  }

  .containerHeader {
    @media (max-width: 600px) {
      padding: 0;
    }
  }

  .containerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    display: inline-block;
    margin-top: 34px;
    font-size: 34px;
    font-family: 'Montserrat';
    line-height: 24px;
    letter-spacing: 0.25px;
    @media screen and (max-width: 900px) {
      font-size: 20px;
      padding-left: 6px;
    }
  }

  .content {
    display: flex;
    gap: 12px;
    margin-top: 30px;
    @media (max-width: 960px) {
      margin-top: 29px;
      padding: 0 6px;
    }
    @media (max-width: 600px) {
      margin-top: 19px;
      padding: 0;
    }

    .leftBlock {
      display: flex;
      flex-direction: column;
      gap: 34px;
      @media (max-width: 960px) {
        display: none;
      }
    }

    .rightBlock {
      flex: 4;
    }

    .tabPanel {
      padding: 0;
    }

    .contentTabPanel {
      padding: 13px 0 0;
      @media screen and (min-width: 1200px) {
        margin-left: 67px;
      }
    }

    .tab {
      &:first-child {
        padding-right: 10px;
        min-width: 140px;
      }
    }
  }
}
